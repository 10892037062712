<template>
  <div v-if="entryForm">

    <entry-form modelType="page" actionText="Create" @submitted="formSubmitted"></entry-form>

  </div>
</template>
<script>
import EntryForm from 'components/super/EntryForm.vue';
import {ENV_URL, MAIN_URL, STRIPE_PUBLISHABLE} from '../../environment.js';
import axios from 'axios';

export default {
  name: 'directory-view',

  components: {
    EntryForm,
  },
  data () {
    console.log('current page', this.$route)
    return {
    }

  },
  created() {

    this.$store.dispatch('LOADING', true);

    const uri = ENV_URL + this.$route.path;

    this.$store.dispatch('SUPER_ENTRIES_CREATE_TYPE', {uri: uri}).then(() => {

      this.$store.dispatch('LOADING', false);

    })

  },

  computed: {
    user() {
      return this.$store.state.user;
    },
    entryForm() {
      return this.$store.state.entryForm;
    },
    entry() {
      return this.$store.state.entry;
    },
  },

  mounted() {

  },

  methods: {
    formSubmitted(e) {

      this.$store.dispatch('LOADING', true);

      var laravelAuthToken = localStorage.getItem('laravelAuthToken');

      let authHeaders = {
        headers: {"Authorization" : `Bearer ${laravelAuthToken}`}
      };

      const item_data = {
        entry_form_fields: this.entryForm.fields,
      }

      if (this.entry && this.entry.categories) {
        item_data.categories = this.entry.categories;
      }

      // /super/contests/{contest}/entries/{id}/update
      let uri = ENV_URL + this.$route.path;
      axios.post(uri, item_data, authHeaders).then((response) => {
        this.$store.dispatch('LOADING', false);
        alert('Your item was created!');
      })
      .catch((e) => {
        console.log('app error: ', error.message);
        this.$store.dispatch('LOADING', false);
        alert("could not create" + error.message);
        
      })
    },

  },

}
</script>

<style lang="scss">

</style>
